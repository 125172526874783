import React, {
  useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useWindowSize } from 'usehooks-ts';

import { useSearchParams } from 'react-router-dom';
import back from './images/back.svg';
import puce from './images/puce.svg';
import play from './images/play.svg';
import close from './images/close.svg';
import logo from '../../assets/images/logo-white.svg';
import { GET_LANDMARK } from '../../actions/types';
import { useAppDispatch } from '../../reducers';
import styles from './LandmarkPanel.module.scss';
import { IField, Tab } from '../../types/scene';
import AudioPlayer from '../AudioPlayer';
import MediaModale from '../MediaModale';

const FieldItem = ({
  field,
  setFullMedia,
}: {
  field: IField,
  setFullMedia: (f: IField) => void
}) => {
  const media = field?.media?.path && `${process.env.REACT_APP_API_URL}/public/${field.media.path}`;
  return (
    <div className={styles.field}>
      {(media && field.type === 'media')
        && <div className={`${styles.media}`}
          onClick={() => setFullMedia(field)}
        >
        {(field?.value?.title || field?.value?.description)
          && <>
            {field?.value?.title && <h3>{field?.value?.title}</h3>}
            {field?.value?.description && <p>{field?.value?.description}</p>}
          </>
          }
          {(field?.media?.mimetype === 'image/png' || field?.media?.mimetype === 'image/jpeg')
                  && <img src={media} alt="cover"/>
                }
          {(field?.media?.mimetype === 'video/mp4')
            && <video controls>
              <source src={media} type="video/mp4" />
              <p>
                Votre navigateur ne prend pas en charge les vidéos HTML5. Voici
                <a href={media}>un lien pour télécharger la vidéo</a>.
              </p>
            </video>
          }
        </div>
      }
      {field?.value && field.type === 'title'
        && <h3>{field?.value}</h3>}

      {media && field.type === 'sound'
        && <div className={styles.sound}>
          <AudioPlayer audioSrc={media} label={field?.value?.title}/>
        </div>
      }

      {field?.value && field.type === 'text'
        && <div className={styles.text} dangerouslySetInnerHTML={{ __html: field.value }} />}

      {field.type === 'separator'
          && <div className={styles.bar} />}

      {(field.type === 'numbered_list' || field.type === 'bulleted_list')
        && <ul>
          {field.value.map((s: any, i: number) => <li key={s.id}>
            {field.type === 'bulleted_list' ? <img src={puce} alt="puce"/>
              : <div className={styles.index}>
              {i + 1}
            </div>
            }
            <p>{s.value}</p>
          </li>)}

        </ul>}
    </div>
  );
};

export default function LandmarkPanel() {
  const { landmark } = useSelector((d: any) => d.scenesReducer);
  const { width } = useWindowSize();

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [coverIsOpen, setCoverIsOpen] = useState<Boolean>(false);
  const [fullMedia, setFullMedia] = useState<IField | null>(null);
  const titleRef = useRef<HTMLDivElement | null>(null);
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const tabHeight = tabsRef?.current?.getBoundingClientRect()?.height;
  const titleHeight = titleRef?.current?.getBoundingClientRect()?.height;

  const media = landmark?.media?.path && `${process.env.REACT_APP_API_URL}/public/${landmark.media.path}`;
  const mediaIsVideo = landmark?.media?.mimetype === 'video/mp4';

  function closePanel() {
    searchParams.delete('fiche');
    setSearchParams(searchParams);
    dispatch({
      type: GET_LANDMARK,
      payload: null,
    });
  }

  function handleOpenCover(bool: boolean) {
    if (mediaIsVideo && videoRef?.current) {
      if (bool) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
    setFullMedia(null);
    setCoverIsOpen(bool);
  }

  const getTabHeight = () => {
    if (tabHeight
     && titleHeight) {
      return tabHeight - titleHeight - 140;
    }
    return 0;
  };

  useEffect(() => {
    if (landmark) {
      setActiveTab(0);
    } else {
      setActiveTab(null);
      handleOpenCover(false);
    }
  }, [landmark]);

  return (
    <div className={`${styles.container} ${landmark ? styles.active : ''}`}>
      {landmark
        && <div className={styles.content}>
            <header>
              <div className={styles.logo}>
                <img src={logo} alt="logo-super-u" />
              </div>
              <button
                className={styles.back}
                onClick={() => closePanel()}
              >
                <img src={back} alt='retour' />
                Vue 360
              </button>
            </header>
            {media
            && <div className={`${styles.cover} ${coverIsOpen ? styles.open : ''} ${mediaIsVideo ? styles.video : ''}`}
            >
               {(landmark.media.mimetype === 'image/png' || landmark.media.mimetype === 'image/jpeg')
                    && <img src={media} onClick={() => setCoverIsOpen(true)} alt="cover"/>
                  }
              {mediaIsVideo
                && <>
                  <video ref={videoRef} controls>
                  <source src={media} type="video/mp4" />
                  <p>
                    Votre navigateur ne prend pas en charge les vidéos HTML5. Voici
                    <a href={media}>un lien pour télécharger la vidéo</a>.
                  </p>
                </video>
                <img
                  src={play}
                  alt='play'
                  onClick={() => handleOpenCover(true)}
                  className={styles.play}
                />
                </>
              }
              <button
                type="button"
                className={styles.close}
                onClick={() => handleOpenCover(false)}
              >
                 <img
                  src={close}
                  alt='close'
                />
              </button>
            </div>
            }
           <button
            className={styles.back}
            onClick={() => closePanel()}
           >
            <img src={back} alt='retour' />
            Retourner à la vue 360
           </button>
           <div ref={tabsRef} className={styles.tabs}>
            <div ref={titleRef}>
              <h1>{landmark.name}</h1>
              {landmark?.tabsCount > 1
                && <div className={styles.toggles}>
                  {landmark.tabs.map((t: Tab, i: number) => <button
                    key={`toggle-${t._id}`}
                    onClick={() => setActiveTab(i)}
                    className={activeTab === i ? styles.active : ''}
                  >
                    <div>
                      <p>{t.description}</p>
                      <h2>{t.name}</h2>
                    </div>
                  </button>)}
              </div>
            }
            {landmark.tabs.map((t: Tab, i: number) => <div
              key={t._id}
              className={`${styles.tab} ${activeTab === i ? styles.active : ''}`}
              style={width > 1024 ? { height: getTabHeight() } : {}}
              >
                {t.fields.map((f) => <FieldItem
                  key={f._id} field={f}
                  setFullMedia={setFullMedia}
                />)}
              </div>)}
            </div>
          </div>
        </div>

      }
      <MediaModale
        media={fullMedia}
        closeModale={() => setFullMedia(null)}
      />
    </div>
  );
}
