/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AUTH_LOGOUT } from './types';

const API_URL = process.env.REACT_APP_API_URL || '';

const errorHandler = async (dispatch: Dispatch, error: any, type: string) => {
  console.log('Error type: ', type, error);
  // const isErrorOnLogin = error.config.url.includes('auth/login');

  if (error?.response?.status === 401) {
    // if (!isErrorOnLogin) {
    //   window.location.href = '/401';
    // }
    dispatch({ type: AUTH_LOGOUT });
    localStorage.removeItem('id');
    localStorage.removeItem('token');
    localStorage.removeItem('specie');
  }
  if (type) {
    dispatch({
      type,
      payload: error.response,
    });
  }
};

const postData = async (errorType: string, url: string, dispatch: Dispatch, data: any, isAuthReq: boolean): Promise<AxiosResponse | null> => {
  const requestUrl = API_URL + url;
  const config: Record<string, any> = {};
  let res: AxiosResponse | null = null;
  let error = null;
  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error as AxiosResponse;
};

const postFormData = async (errorType: string, isAuthReq: boolean, url: string, dispatch: Dispatch, data: any) => {
  const requestUrl = API_URL + url;
  const config: Record<string, any> = {
    timeout: 1000 * 60,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  let res = null;
  let error = null;

  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error as AxiosResponse;
};

const putFormData = async (errorType: string, isAuthReq: boolean, url: string, dispatch: Dispatch, data: any) => {
  const requestUrl = API_URL + url;
  const config: Record<string, any> = {
    timeout: 1000 * 60,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  let res = null;
  let error = null;

  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.put(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error as AxiosResponse;
};

const getFile = async (errorType: string, url: string, dispatch: Dispatch, isAuthReq = false) => new Promise((resolve, reject) => {
  const requestUrl = API_URL + url;
  const token = localStorage.getItem('token');

  const config : any = {
    responseType: 'blob',
    headers: {
      Authorization: token,
    },
  };

  axios.get(requestUrl, isAuthReq ? config : null)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject();
      errorHandler(dispatch, error, errorType);
    });
});
const getData = async (errorType: string, url: string, dispatch: Dispatch, isAuthReq: boolean) => {
  const requestUrl = API_URL + url;
  const config: Record<string, any> = {};
  let res = null;
  let error = null;
  if (isAuthReq) {
    const token = localStorage.getItem('token');
    config.headers = { Authorization: `${token}` };
  }

  try {
    res = await axios.get(requestUrl, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error as AxiosResponse;
};

const putData = async (errorType: string, url: string, dispatch: Dispatch, data: any, isAuthReq: boolean) => {
  const requestUrl = API_URL + url;
  const config: Record<string, any> = {};
  let res = null;
  let error = null;
  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.put(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, error, errorType);
  }
  return res?.status === 200 ? res : error as AxiosResponse;
};

// eslint-disable-next-line max-len
const deleteData = async (errorType: string, url: string, dispatch: Dispatch, isAuthReq: boolean) => new Promise((resolve, reject) => {
  const requestUrl = API_URL + url;

  const config: Record<string, any> = {};

  if (isAuthReq) {
    const token = localStorage.getItem('token');
    config.headers = {
      Authorization: `${token}`,
    };
  }

  axios.delete(requestUrl, config)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject();
      errorHandler(dispatch, error, errorType);
    });
});

export {
  errorHandler,
  getFile,
  postData,
  postFormData,
  putFormData,
  getData,
  putData,
  deleteData,
};
