import { Dispatch } from 'redux';
import {
  deleteData, getData, postData, putData,
} from '.';
import { IScene } from '../types/scene';
import {
  GET_SCENE,
  GET_SCENES,
  GET_SCENES_LIST,
  GET_SCENE_LANDMARKS,
  POST_LANDMARK,
  GET_LANDMARK,
  PUT_SCENE,
  SCENE_ERROR,
  SCENE_LOADING,
  SET_TOAST,
  DELETE_LANDMARK,
} from './types';

export const getScenesAction = async (dispatch: Dispatch) => {
  const url = '/scene';
  dispatch({
    type: SCENE_LOADING,
    payload: GET_SCENES,
  });
  const response = await getData(SCENE_ERROR, url, dispatch, true);
  if (response?.data?.scenes) {
    dispatch({
      type: GET_SCENES,
      payload: response.data.scenes.sort(
        (a: IScene, b: IScene) => (a.position > b.position ? 1 : -1),
      ),
    });
  }
};

export const putSceneAction = async (dispatch: Dispatch, data: any) => {
  const { _id, ...updatedPage } = data;
  const url = `/scene/${_id}`;
  const response = await putData(SCENE_ERROR, url, dispatch, updatedPage, true);
  if (response?.data?.scene) {
    dispatch({
      type: PUT_SCENE,
      payload: response.data?.scene,
    });
  }
  return response;
};

export const getSceneAction = async (dispatch: Dispatch, id: string) => {
  const url = `/scene/${id}`;
  dispatch({
    type: SCENE_LOADING,
    payload: GET_SCENE,
  });
  const response = await getData(SCENE_ERROR, url, dispatch, true);
  if (response?.data?.scene) {
    dispatch({
      type: GET_SCENE,
      payload: response.data.scene,
    });
  }
};

export const updateSceneAction = async (
  dispatch: Dispatch,
  id: string,
  data: any,
) => {
  const url = `/scene/${id}`;
  dispatch({
    type: SCENE_LOADING,
    payload: GET_SCENE,
  });
  const response = await putData(SCENE_ERROR, url, dispatch, data, true);
  if (response?.data?.scene) {
    dispatch({
      type: GET_SCENE,
      payload: response.data.scene,
    });
  }
};

export const getScenesListsAction = async (dispatch: Dispatch) => {
  const url = '/scene/lists';
  dispatch({
    type: SCENE_LOADING,
  });
  const response = await getData(SCENE_ERROR, url, dispatch, true);
  if (response?.data) {
    dispatch({
      type: GET_SCENES_LIST,
      payload: response.data,
    });
  }
};

export const getSceneLandmarksAction = async (dispatch: Dispatch, id: string) => {
  const url = `/landmark/scene/${id}`;
  dispatch({
    type: SCENE_LOADING,
    payload: GET_SCENE_LANDMARKS,
  });
  const response = await getData(SCENE_ERROR, url, dispatch, true);
  if (response?.data?.landmarks) {
    dispatch({
      type: GET_SCENE_LANDMARKS,
      payload: response.data.landmarks,
    });
  }
};

export const postLandmarksAction = async (dispatch: Dispatch, data: any) => {
  const url = '/landmark';
  dispatch({
    type: SCENE_LOADING,
    payload: POST_LANDMARK,
  });
  const response = await postData(SCENE_ERROR, url, dispatch, data, true);

  if (response?.data?.landmark) {
    dispatch({
      type: POST_LANDMARK,
      payload: response?.data.landmark,
    });
  }
  return response?.data?.landmark;
};

export const getLandmarkAction = async (dispatch: Dispatch, id: string) => {
  const url = `/landmark/${id}`;
  dispatch({
    type: SCENE_LOADING,
  });
  const response = await getData(SCENE_ERROR, url, dispatch, true);
  if (response?.data?.landmark) {
    dispatch({
      type: GET_LANDMARK,
      payload: response.data.landmark,
    });
  }
  return response;
};

export const putLandmarkAction = async (
  dispatch: Dispatch,
  id: string,
  data: any,
  isPosition : boolean = false,
) => {
  const url = `/landmark/${id}`;
  dispatch({
    type: SCENE_LOADING,
  });
  const response = await putData(SCENE_ERROR, url, dispatch, data, true);
  if (response?.data?.landmark) {
    dispatch({
      type: GET_LANDMARK,
      payload: response.data.landmark,
    });
    if (!isPosition) {
      dispatch({
        type: SET_TOAST,
        payload: {
          type: 'success',
          message: 'Point de repére sauvegardé',
        },
      });
    }
  }
  return response;
};

export const deleteLandmarkAction = async (dispatch: Dispatch, id: string) => {
  const url = `/landmark/${id}`;
  dispatch({
    type: SCENE_LOADING,
  });
  const response = await deleteData(SCENE_ERROR, url, dispatch, true);
  if (response) {
    dispatch({
      type: DELETE_LANDMARK,
      payload: id,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Point de repére supprimé',
      },
    });
  }
  return response;
};
