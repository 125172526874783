/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useEffect } from 'react';
// import * as THREE from 'three';
import DragControls from 'three-dragcontrols';
import { BiTargetLock } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import {
  Viewer, ImagePanorama, Infospot,
} from 'panolens';
import styles from './Panorama.module.scss';
// import markerImg from './images/marker.png';
import arrow from './images/arrow.svg';
import markerSvg from './images/marker.svg';
import { useAppDispatch } from '../../reducers';
import { getLandmarkAction, putSceneAction } from '../../actions/scene';
import { SET_TOAST } from '../../actions/types';

const captureImage = async (id) => {
  // Get the HTML element reference
  const element = document.getElementById(id);
  let img = null;
  if (element?.style) {
    const savedStyle = { ...element.style };
    element.style.display = 'flex';
    element.style.backgroundColor = 'transparent';
    // console.log(element);
    // Use html2canvas to capture the element and create a PNG image
    await html2canvas(element, {
      backgroundColor: null,
    })
      .then((canvas) => {
      // Convert canvas to data URL or Blob
        img = canvas.toDataURL('image/png');
      // Do something with the dataUrl, e.g., display in an image tag or send to serve
      })
      .catch((error) => {
        console.error('Error capturing image:', error);
      });

    element.style = savedStyle;
  }
  return {
    src: img,
    scale: 1000,
  };
};

function PanoramaViewer({
  picture, markers, scenes, addMarker, putMarker, putScene,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const viewerContainer = useRef(null);
  const viewer = useRef(null);
  const panorama = useRef(null);
  const { user } = useSelector((d) => d.authReducer);
  const { scene } = useSelector((d) => d.scenesReducer);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const createHTMLMarker = async (x, y, z, markerData) => {
    const img = await captureImage(`label-${markerData._id}`);
    const marker = new Infospot(img.scale, img.src, false);
    // eslint-disable-next-line no-mixed-operators
    marker.position.set(x, y, z);
    marker.userData.id = markerData._id;
    marker.userData.position = { x, y, z };
    return marker;
  };

  function handleMarkerClick(e, url) {
    navigate(url);
  }

  async function setCameraPosition() {
    const cameraPosition = viewer.current.camera.position;
    const res = await putSceneAction(dispatch, { ...scene, cameraPosition });
    if (res?.data) {
      dispatch({
        type: SET_TOAST,
        payload: {
          type: 'success',
          message: 'la position est sauvegardé',
        },
      });
    }
  }

  const handlePanoramaClick = async () => {
    const intersects = viewer.current.raycaster.intersectObject(panorama.current, true);
    if (intersects?.length > 0) {
      const clickPosition = intersects[0].point;
      if (addMarker !== null) {
        const markerData = await addMarker({
          x: -clickPosition.x,
          y: clickPosition.y,
          z: clickPosition.z,
        });
        if (markerData) {
          navigate(`/admin/${markerData.scene}/${markerData._id}${location.search}`);
        }
      }
    }
  };

  const loadPanorama = async (panoramaImagePath) => {
    // Create an image panorama
    console.log('draw');
    viewer.current = new Viewer({
      container: viewerContainer.current,
      autoHideInfospot: false,
      output: 'console',
      controlBar: false,
      // enableReticle: true,
    });

    if (user) {
      const zoomControl = viewer.current.getControl();
      zoomControl.noZoom = true;
    }

    viewer.current.camera.fov = 100;
    viewer.current.camera.updateProjectionMatrix();

    // Get the camera's position

    // Set the zoom delta
    // zoomControl?.setZoomDelta(0.1);
    panorama.current = new ImagePanorama(panoramaImagePath);
    if (markers !== null) {
      const objects = [];
      for await (const m of markers) {
        const { x, y, z } = m.position;
        const marker = await createHTMLMarker(x, y, z, m);
        if (marker && user) {
          if (putMarker) {
            marker.addEventListener('click', (e) => {
              handleMarkerClick(e, `/admin/${m.scene}/${m._id}`);
            });
          }
          objects.push(marker);
        }
        if (marker && !addMarker) {
          marker.addEventListener('click', () => {
            searchParams.set('fiche', m._id);
            setSearchParams(searchParams);
            getLandmarkAction(dispatch, m._id);
          });
        }
        panorama.current.add(marker);
      }
      if (objects?.length > 0 && user && putMarker) {
        const dragcontrols = new DragControls(
          objects,
          viewer.current.camera,
          viewer.current.renderer.domElement,
        );
        dragcontrols.addEventListener('dragstart', () => {
          viewer.current.control.enabled = false;
        });

        dragcontrols.addEventListener('drag', (event) => {
          const intersects = viewer.current.raycaster.intersectObject(panorama.current, true);
          if (intersects?.length > 0) {
            const clickPosition = intersects[0].point;
            event.object.position.x = -clickPosition.x;
            event.object.position.y = clickPosition.y; // Reverse Y movement
            event.object.position.z = clickPosition.z; // Reverse Z movement
          }
        });

        dragcontrols.addEventListener('dragend', (event) => {
          const currentPos = JSON.stringify(event.object.position);
          const newPos = JSON.stringify(event.object.userData.position);
          if (currentPos !== newPos) {
            putMarker(event.object.userData.id, event.object.position);
          }
          viewer.current.control.enabled = true;
        });
      }
    }
    if (scenes !== null) {
      const objects = [];
      for await (const s of scenes) {
        const { x, y, z } = s.position;
        const marker = await createHTMLMarker(x, y, z, s);
        if (marker) {
          marker.addEventListener('click', (e) => handleMarkerClick(e, `/showroom/${s._id}${location.search}`));
        }
        if (user && marker) {
          objects.push(marker);
        }
        panorama.current.add(marker);
      }
      if (objects?.length > 0 && user && putScene) {
        const dragcontrols = new DragControls(
          objects,
          viewer.current.camera,
          viewer.current.renderer.domElement,
        );
        dragcontrols.addEventListener('dragstart', () => {
          viewer.current.control.enabled = false;
        });

        dragcontrols.addEventListener('drag', (event) => {
          const intersects = viewer.current.raycaster.intersectObject(panorama.current, true);
          if (intersects?.length > 0) {
            const clickPosition = intersects[0].point;
            event.object.position.x = -clickPosition.x;
            event.object.position.y = clickPosition.y; // Reverse Y movement
            event.object.position.z = clickPosition.z; // Reverse Z movement
          }
        });

        dragcontrols.addEventListener('dragend', (event) => {
          const currentPos = JSON.stringify(event.object.position);
          const newPos = JSON.stringify(event.object.userData.position);
          if (currentPos !== newPos) {
            putScene(event.object.userData.id, event.object.position);
          }
          viewer.current.control.enabled = true;
        });
      }
    }
    viewer.current.add(panorama.current);
    if (putMarker !== null && viewerContainer?.current) {
      viewerContainer?.current?.addEventListener('click', (e) => handlePanoramaClick(e));
    }
    if (scene?.cameraPosition) {
      const { x, y, z } = scene.cameraPosition;
      viewer.current.camera.position.set(x, y, z);
      viewer.current.camera.updateProjectionMatrix();
    }
    // console.log(viewer?.current?.control);
    // viewer.current.control.addEventListener('change', () => {
    //   const altitude = viewer.current.camera.rotation.x; // Get the current altitude angle
    //   console.log('altitude', altitude);
    //   const minAltitude = -0.40; // Define the minimum altitude angle (-45 degrees)
    //   const maxAltitude = -0.10; // Define the maximum altitude angle (45 degrees)
    //   console.log('altitude', altitude, minAltitude);
    //   if (altitude < minAltitude) {
    //     viewer.current.camera.rotation.x = minAltitude; // Limit to the minimum altitude angle
    //   } else if (altitude < maxAltitude) {
    //     viewer.current.camera.rotation.x = maxAltitude; // Limit to the maximum altitude angle
    //   }
    // });
  };

  useEffect(() => {
    if (picture) {
      loadPanorama(picture);
    }
  }, []);

  return (
    <>
    {putMarker
      && <button
        type="button"
        className={`${styles.camera} ${styles.primary} ${styles['with-icon']}`}
        onClick={() => setCameraPosition()}
      >
        <BiTargetLock />Caler ma vue
      </button>

    }
    <div
      ref={viewerContainer}
      style={{ width: '100%', height: '100%' }}
    >

    </div>
    {markers?.map((m) => <div
      key={m._id}
      className={styles.marker}
    >
      <div className={styles.content}
        id={`label-${m._id}`}
        style={{ display: 'none', maxWidth: 300 }}
      >
        <img src={markerSvg} alt={m.name}/>
        <h2>{m.name}</h2>
      </div>
    </div>)}
      {scenes?.map((m) => <div
        key={m._id}
        className={`${styles.scene}`}
      >
        <div
          id={`label-${m._id}`}
          style={{ display: 'none' }}
          className={styles.content}
        >
          <div className={styles.circle}>
            <h2>{m.name}</h2>
          </div>
          <div className={styles.arrow}>
            <img src={arrow} alt="arrow" />
          </div>
        </div>
      </div>)}
    </>

  );
}

export default PanoramaViewer;
