import React from 'react';
import { IoTrash } from 'react-icons/io5';
import { BiMoveVertical } from 'react-icons/bi';
import { RiDeleteBin6Fill } from 'react-icons/ri';

import { useFormContext } from 'react-hook-form';
import styles from './Field.module.scss';

import { InputText } from '../../lib/HooksFormFields';
import { IField } from '../../types/scene';
import TextEditor from '../../lib/HooksFormFields/TextEditor';
import ListEditor from '../../lib/HooksFormFields/ListEditor';
import Textarea from '../../lib/HooksFormFields/Textarea';
import InputFile from '../InputFile/InputFile';
import deleteFile from '../../actions/file';
import { useAppDispatch } from '../../reducers';
// import Checkbox from '../../lib/HooksFormFields/Checkbox';

const Field = ({
  field,
  control,
  name,
  removeField,
  saveLandmark,
}:{
  field: IField,
  name: string,
  control: any,
  saveLandmark: (data: any) => void,
  removeField?: (id: string) => void,
}) => {
  const dispatch = useAppDispatch();
  const { watch, setValue } = useFormContext();
  const prevFile = watch(`${name.replace('.value', '')}.media`);
  const media = prevFile?.path && `${process.env.REACT_APP_API_URL}/public/${prevFile.path}`;

  async function handleSaveFile(id:string | null = null) {
    if (prevFile?._id) {
      await deleteFile(dispatch, prevFile?._id);
    }
    await setValue(`${name.replace('.value', '')}.media`, id);
    const landmark = { ...watch() };
    await saveLandmark(landmark);
  }

  return (
    <div className={`${styles.field}`}
    >
      {field.type === 'title'
        && <div className={styles.title}>
          <Textarea
            label={''}
            name={name || ''}
            control={control}
            className={'title'}
            rules={{ }}
          />
        </div>
      }
      {field.type === 'text'
        && <div className={styles.text}>
            <TextEditor
              label={''}
              name={name || ''}
              bounds={'#content'}
              control={control}
              rules={{ }}
            />
        </div>
      }
      {field.type === 'numbered_list'
        && <div className={styles.list}>
            <ListEditor
              name={name}
              isNumber={true}
              control={control}
            />
        </div>
      }
      {field.type === 'bulleted_list'
        && <div className={styles.list}>
            <ListEditor
              name={name}
              isNumber={false}
              control={control}
            />
        </div>
      }
      {field.type === 'media'
        && <div className={styles.media}>
            <div className={styles.picture}>
              {prevFile
                ? <>
                {(prevFile.mimetype === 'image/png' || prevFile.mimetype === 'image/jpeg')
                        && <img src={media} className={styles.image} alt="cover"/>
                      }
                      {(prevFile.mimetype === 'video/mp4')
                        && <video controls>
                          <source src={media} className={styles.image} type="video/mp4" />
                          <p>
                            Votre navigateur ne prend pas en charge les vidéos HTML5. Voici
                            <a href={media}>un lien pour télécharger la vidéo</a>.
                          </p>
                        </video>
                      }
                      <button
                        type="button"
                        className={styles.icon}
                        onClick={() => handleSaveFile()}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                </>
                : <InputFile
                submit={(id: string) => handleSaveFile(id)}
                id={`${name}.media`}
                btnText="Parcourir"
                className="browse"
              />
              }
            </div>
            <div className={styles.infos}>
              <div className={styles.input}>
                <InputText
                  label={''}
                  name={`${name}.title` || ''}
                  control={control}
                  placeholder={'Titre'}
                  rules={{ }}
                />
              </div>
              <div className={styles.input}>
                <Textarea
                  label={''}
                  name={`${name}.description` || ''}
                  control={control}
                  placeholder={'Description'}
                  rules={{ }}
                />
              </div>
            </div>
        </div>
      }
      {field.type === 'sound'
        && <div className={`${styles.sound} ${!prevFile ? styles.browse : ''}`}>
            <div className={styles.mp3}>
              {prevFile
                ? <>
                  {(prevFile.mimetype === 'audio/mpeg3' || prevFile.mimetype === 'audio/mpeg')
                    && <audio controls>
                      <source src={media} type="audio/mpeg3" />
                      <source src={media} type="audio/mpeg" />
                    </audio>
                  }
                  <button
                    type="button"
                    className={styles.icon}
                    onClick={() => handleSaveFile()}
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </>
                : <InputFile
                submit={(id: string) => handleSaveFile(id)}
                id={`${name}.media`}
                btnText="Parcourir"
                className="primary"
              />
              }
            </div>
          <div className={styles.input}>
            <InputText
              label={''}
              name={`${name}.title` || ''}
              control={control}
              placeholder={'Titre du son'}
              rules={{ }}
            />
          </div>
        </div>
      }
      {field.type === 'separator'
        && <div className={styles.separator}>
          <div className={styles.bar} />
        </div>
      }
      {field._id && removeField
        && <div className={styles.actions}>
          <button
            type='button'
            className={styles.icon}
            onClick={() => removeField(field._id || '')}
          >
            <IoTrash />
          </button>
          <button
            type='button'
            className={styles.icon}
          >
            <BiMoveVertical />
          </button>
        </div>
      }
    </div>
  );
};

export default Field;
