import { Dispatch } from 'redux';
import { deleteData } from '.';
import { FILE_ERROR } from './types';

const deleteFile = async (dispatch: Dispatch, id: string) => {
  const url = `/files/${id}`;
  const response: any = await deleteData(FILE_ERROR, url, dispatch, true);
  return response;
};

export default deleteFile;
