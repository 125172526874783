/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useEffect, useState, useCallback,
} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import styles from './TabForm.module.scss';
import Field from '../Field';
// import { postSectionTemplateAction } from '../../../actions/ecrfs';
import { IField, Tab } from '../../types/scene';
import AddField from '../AddField/AddField';
import { ErrorField, InputText } from '../../lib/HooksFormFields';

export default function TabForm(
  {
    tab,
    tabIndex,
    saveLandmark,
    isUniqueTab,
  }:
  {
    tab: Tab,
    tabIndex: number;
    isUniqueTab: boolean,
    saveLandmark: (data:any) => void,
  },
) {
  // const dispatch = useAppDispatch();

  // const height = contentRef?.current?.getBoundingClientRect().height;
  const { watch, control, formState } = useFormContext();
  const { errors } = formState;

  const [fieldsList, setFieldsList] = useState<IField[]>([]);

  const getTabsError = () => {
    const err : any = { ...errors };
    if (err?.tabs && err?.tabs[tabIndex]) {
      return err.tabs[tabIndex];
    }
    return {};
  };

  function addField(field: IField) {
    const landmark = { ...watch() };
    if (tabIndex === -1) {
      return null;
    }
    landmark.tabs[tabIndex].fields = [...landmark.tabs[tabIndex].fields, field].filter(
      (f) => f.type,
    );
    return saveLandmark(landmark);
  }

  const reorder = (
    list: Iterable<unknown> | ArrayLike<unknown>,
    startIndex: number,
    endIndex: number,
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result: { source: any; destination: any; }) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    let items : any = [...tab.fields];
    if (source.droppableId === destination.droppableId) {
      items = reorder(items, source.index, destination.index);
      const landmark = { ...watch() };
      if (tabIndex === -1) {
        return;
      }
      landmark.tabs[tabIndex].fields = [...items];
      saveLandmark(landmark);
    }
  }

  const removeField = useCallback((id: string) => {
    const landmark = { ...watch() };
    if (tabIndex === -1) {
      return;
    }
    landmark.tabs[tabIndex].fields = [
      ...landmark.tabs[tabIndex].fields.filter((f: IField) => f._id !== id)];
    saveLandmark(landmark);
  }, [fieldsList]);

  useEffect(() => {
    if (tab.fields) {
      setFieldsList(tab.fields);
    }
  }, [tab.fields]);

  return (
    <section className={`${styles.tab}`}>
      {!isUniqueTab
        && <div className={styles.header}>
            <h2>Onglet {tabIndex + 1}</h2>
          <div className={styles.infos}>
            <div className={styles.input}>
              <InputText
                name={`tabs.${tabIndex}.name`}
                control={control}
                placeholder="Titre de l'onglet"
                rules={{
                  required: 'Ce champs est requis',
                }}
              />
              {getTabsError()?.name?.message
              && <ErrorField message={getTabsError()?.name?.message}/>}
            </div>
            <div className={styles.input}>
              <InputText
                name={`tabs.${tabIndex}.description`}
                control={control}
                placeholder="Sujet"
                rules={{

                }}
              />
              {getTabsError()?.description?.message
              && <ErrorField message={getTabsError()?.description?.message}/>}
            </div>

          </div>
        </div>
      }
      <div className={styles.fields}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`${tab._id}`} type="field">
            {(provided: any) => (
              <div
                ref={provided.innerRef}
                className={`${styles['fields-list']} `}
                >
                {fieldsList.map((f, index) => (
                  <Draggable
                      key={f._id}
                      draggableId={`field-${f._id}`}
                      index={index}>
                      {(providedDraggable: any) => (
                          <div
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                          >
                            <Field
                              key={f._id}
                              field={f}
                              name={`tabs.${tabIndex}.fields.${index}.value`}
                              removeField={(id: string) => removeField(id)}
                              saveLandmark={saveLandmark}
                              control={control}
                              />
                        </div>
                      )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {tab?._id
        && <AddField
          addField={(input : any) => addField(input)}
        />
      }
    </section>
  );
}
