import React, { ReactNode } from 'react';
import Nav from '../Nav';
import styles from './layout.module.scss';

interface LayoutProps {
  children: ReactNode;
  role: string;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <div className={styles.layout}>
    <div className={styles.nav}>
      <Nav />
    </div>
    <div id="content" className={styles.container}>
      {children}
    </div>
  </div>
);

export default Layout;
