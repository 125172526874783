/* eslint-disable react/prop-types */
import React, {
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  getScenesAction, putSceneAction,
} from '../../actions/scene';
import Loader from '../../components/Loader';
import logo from '../../assets/images/logo-white.svg';
// import Viewer360 from '../../components/viewer360';
import { useAppDispatch } from '../../reducers';
import styles from './showroom.module.scss';
import showroomImg from './images/accueil.jpg';
import PanoramaViewer from '../../components/PanoramaViewer';
import { GET_SCENES } from '../../actions/types';
import { IScene, MarkerPos } from '../../types/scene';
import ShowroomNav from '../../components/ShowroomNav';

export default function Showroom({ isNav = false }) {
  const dispatch = useAppDispatch();
  const { isLoading, scenes } = useSelector((d: any) => d.scenesReducer);
  const location = useLocation();
  console.log(location.search);
  const sceneIsLoading = isLoading?.find(
    (l: string) => l === GET_SCENES,
  );
  const scenesList = scenes?.filter(
    (s: IScene) => s.picture && s.showroomPosition,
  ).map((s: IScene) => ({ ...s, position: s.showroomPosition }));

  const handlePutScene = async (id: string, position: MarkerPos) => {
    if (id) {
      const res = await putSceneAction(dispatch, { _id: id, showroomPosition: position });
      return res;
    }
    return null;
  };

  useEffect(() => {
    getScenesAction(dispatch);
  }, []);

  return (
    <div className={styles.container}>
      {!sceneIsLoading
        ? <>
          <ShowroomNav defaultActive={isNav}/>
          <header>
            <Link to={`/${location.search}`} className={styles.logo}>
              <img src={logo} alt="logo-super-u" />
            </Link>
            {!isNav
              && <h1>Showroom</h1>
            }
            <div className={styles.toggle}>
            </div>
          </header>
          {!isNav
          && <div className={`${styles.img}`}>
              {scenesList
                && <PanoramaViewer
                  picture={showroomImg}
                  scenes={scenesList}
                  putScene={handlePutScene}
                  markers={null}
                  addMarker={null}
                  putMarker={null}
                />
              }
            </div>
          }
        </>
        : <div className={styles.loader}>
        <Loader />
      </div>
      }
    </div>
  );
}
