import React, { useCallback, useRef } from 'react';
import add from './icons/add.svg';
import title from './icons/title.svg';
import separator from './icons/separator.svg';
import richText from './icons/richText.svg';
import numbered from './icons/numbered.svg';
import media from './icons/media.svg';
import sound from './icons/sound.svg';
import puce from './icons/puce.svg';
import useComponentVisible from '../../hooks/useComponentVisible';
// import { FieldForm } from '../../../types/ecrf.types';
import styles from './AddField.module.scss';
import { IField } from '../../types/scene';

export const fieldOptions = [
  {
    label: 'Titre',
    icon: title,
    field: {
      type: 'title',
      value: '',
    },
  },
  {
    label: 'Texte',
    icon: richText,
    field: {
      type: 'text',
      value: '',
    },
  },
  {
    label: 'Image',
    icon: media,
    field: {
      type: 'media',
      value: {
        media: null,
        title: '',
        description: '',
      },
    },
  },
  {
    label: 'Liste numérotée',
    icon: numbered,
    field: {
      type: 'numbered_list',
      value: [{ id: new Date().getTime().toString(), value: '' }],
    },
  },
  {
    label: 'Liste à puces',
    icon: puce,
    field: {
      type: 'bulleted_list',
      value: [{ id: new Date().getTime().toString(), value: '' }],
    },
  },
  {
    label: 'Séparateur',
    icon: separator,
    field: {
      type: 'separator',
      value: '',
    },
  },
  {
    label: 'Fichier son',
    icon: sound,
    field: {
      type: 'sound',
      value: {
        media: null,
        title: '',
        description: '',
      },
    },
  },
];

export default function AddField({
  addField,
  width,
}:{
  width?: number,
  addField: (field : IField) => void
}) {
  const btnRef = useRef<HTMLDivElement | null>(null);
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const rect = btnRef?.current?.getBoundingClientRect();

  const getStyle = useCallback(() => {
    const style = {
      top: rect?.y ? rect.y - 150 : 0,
      left: rect?.x ? rect.x + (rect.width / 2) : 0,
    };
    if (rect?.top && window.innerHeight - rect.top < 215) {
      style.top -= 120;
    }
    return style;
  }, [rect]);

  async function handleAddField(field: IField) {
    await addField({ ...field });
    setIsComponentVisible(false);
  }

  return (
    <div
      ref={btnRef}
      className={`${styles.container} ${isComponentVisible ? styles.open : ''}`}
      style={width ? {
        width: width || 'auto',
      } : {}
      }
    >
      <button
        type="button"
        className={styles.toggle}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
       <img src={add} alt='add-field' />
      </button>
      <div>
        {isComponentVisible && (
          <div ref={ref}
          className={styles.dropdown}
            style={getStyle()}
          >
            {fieldOptions.map((opt) => (
              <button
                key={`dropdown-${opt.field.type}`}
                type='button'
                onClick={() => handleAddField(opt.field)}
              >
               <img src={opt.icon} alt={opt.label}/><p>{opt.label}</p>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
