import React, {
  useEffect, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Link, useLocation, useParams, useSearchParams,
} from 'react-router-dom';
import {
  getSceneAction, getSceneLandmarksAction, getLandmarkAction,
} from '../../actions/scene';
import Loader from '../../components/Loader';
import logo from '../../assets/images/logo-white.svg';
// import Viewer360 from '../../components/viewer360';
import { useAppDispatch } from '../../reducers';
import styles from './scene.module.scss';
import PanoramaViewer from '../../components/PanoramaViewer';
import LandmarkPanel from '../../components/LandmarkPanel';
import { GET_SCENE_LANDMARKS, GET_SCENE } from '../../actions/types';
import ShowroomNav from '../../components/ShowroomNav';

export default function Scene() {
  const { sceneId } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams()[0];
  // const cameraRef = useRef<any>(null);
  const { scene, landmarks, isLoading } = useSelector((d: any) => d.scenesReducer);
  const sceneIsLoading = isLoading?.find(
    (l: string) => l === GET_SCENE_LANDMARKS,
  );

  const picture = !sceneIsLoading && landmarks && scene?.picture?.path && `${process.env.REACT_APP_API_URL}/public/${scene.picture.path}`;

  const getScene = useCallback(() => {
    if (sceneId) {
      dispatch({
        type: GET_SCENE,
        payload: null,
      });
      dispatch({
        type: GET_SCENE_LANDMARKS,
        payload: null,
      });
      getSceneAction(dispatch, sceneId);
      getSceneLandmarksAction(dispatch, sceneId);
    }
  }, [dispatch, sceneId]);

  useEffect(() => {
    getScene();
  }, [sceneId]);

  useEffect(() => {
    if (searchParams) {
      const landmarkId = searchParams.get('fiche');
      if (landmarkId) {
        getLandmarkAction(dispatch, landmarkId);
      }
    }
  }, [searchParams]);
  return (
    <div className={styles.container}>
      {!sceneIsLoading && scene
        ? <>
          <Link to={`/${location.search}`} className={styles.logo}>
            <img src={logo} alt="logo-super-u" />
          </Link>
          <header>
            <div className={styles.toggle}>
            </div>
            <h1>{scene?.name}</h1>
            <div className={styles.toggle}>
              <ShowroomNav />
            </div>
          </header>
          <div className={`${styles.img}`}>
              {picture && landmarks
                && <PanoramaViewer
                  picture={picture}
                  scenes={null}
                  putScene={null}
                  markers={landmarks}
                  addMarker={null}
                  putMarker={null}
                />
              }
            </div>
        </>
        : <div className={styles.loader}>
        <Loader />
      </div>
      }
      <LandmarkPanel />
    </div>
  );
}
