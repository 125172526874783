import React, { useState, useRef } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa6';
import styles from './AudioPlayer.module.scss';

interface AudioPlayerProps {
  audioSrc: string;
  label: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioSrc, label }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className={styles.player}>
      <audio ref={audioRef} src={audioSrc} />
      <button onClick={togglePlayPause}>
        {isPlaying ? <FaPause /> : <FaPlay />}
      </button>
      <p>{label}</p>
    </div>
  );
};

export default AudioPlayer;
