import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import logo from '../../assets/images/logo-white.svg';
import { resetPassword } from '../../actions/auth';
import ResetPwdForm from '../../components/form/AuthForm/ResetPwdForm';

import styles from './reset-password.module.scss';

const ResetPassword = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const [succeed, setSucceed] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  function submitReset(data: FieldValues) {
    resetPassword(dispatch, {
      password: data.password,
      token: params?.token || '',
    }).then((res: any) => {
      if (res?.status === 200) setSucceed(true);
    });
  }

  return (
    <div className={styles['reset-password']}>
     <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.content}>
        <div className={styles.form}>
          <h2>Mot de passe oublié</h2>
          <ResetPwdForm
            submit={submitReset}
            signinLink={succeed ? '/login' : null}
            succeedMessage={
              succeed ? 'Votre mot de passe a été mis à jour.' : null
            }
            errorMessage={authReducer.error}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
