import React from 'react';
import { FiLogIn } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import {
  Link, NavLink, useLocation,
} from 'react-router-dom';

import { logout, exportHistoryAction } from '../../actions/auth';
import logo from '../../assets/images/logo-dark.svg';
import { useAppDispatch } from '../../reducers';
import styles from './nav.module.scss';
import scenesProperties from '../../constants/scenes/scenes';

const SceneLink = ({ scene, index }: { scene: any, index: number }) => {
  const location = useLocation();

  return (
  <NavLink
    to={`/admin/${scene._id}${location.search}`}
    className={({ isActive }) => (isActive ? styles.active : '')}
  >
    <div className={styles.bar} />
    <div className={styles.icon}>
      {scenesProperties[index]?.icon
        && <img src={scenesProperties[index]?.icon} alt={scene.name} />}
    </div>
    <p>{scene.name}</p>
  </NavLink>
  );
};

export default function Nav() {
  const dispatch = useAppDispatch();
  const { scenes } = useSelector((d: any) => d.scenesReducer);

  async function handleLogout() {
    const token = localStorage.getItem('token');
    console.log(token);
    const isLogout = null || await logout(dispatch);
    if (isLogout) {
      window.location.href = `${process.env.REACT_APP_API_URL}/redirect?referer=b@k@s@ble`;
    }
  }

  return (
    <div className={styles.nav}>
      <Link to="/" className={styles.logo}>
        <img src={logo} alt='logo-super-u' />
      </Link>
      <div className={styles.links}>
        {scenes?.map((s :any, i: number) => <SceneLink key={s._id} scene={s} index={i}/>)}
      </div>
      <div>
        <button
          type="button"
          className={styles.export}
          onClick={() => exportHistoryAction(dispatch)}
        >
          Exporter les données de navigation
        </button>
        <div className={styles.logout}>
          <button
            type="button"
            onClick={() => handleLogout()}
          >
            <FiLogIn />
            <p>Se déconnecter</p>
          </button>
        </div>
      </div>
    </div>
  );
}
