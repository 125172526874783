import React, { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileAction, signIn } from '../../actions/auth';

import logo from '../../assets/images/logo-white.svg';
import SignInForm from '../../components/form/AuthForm/SignInForm';

import styles from './signin.module.scss';
import { getScenesAction } from '../../actions/scene';

const SignIn = () => {
  const { user, error } = useSelector((d: any) => d.authReducer);
  const { scenes } = useSelector((d: any) => d.scenesReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getProfileAction(dispatch);
    }
  }, []);

  useEffect(() => {
    if (user?.role) {
      getScenesAction(dispatch);
    }
  }, [user]);

  useEffect(() => {
    if (user?.role === 'Admin' && scenes?.length > 0) {
      navigate(`/admin/${scenes[0]?._id}`);
    }
  }, [scenes]);
  console.log(user);

  function submit(data: FieldValues) {
    signIn(dispatch, data);
  }

  return (
    <div className={styles.signin}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.content}>
        <div className={styles.form}>
          <h2>Connexion</h2>
          <SignInForm
            forgotPwdLink="/forgot-password"
            errorMessage={error}
            submit={submit}
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
