import React from 'react';
import {
  Controller, useFieldArray, Control,
} from 'react-hook-form';
import { IoTrash } from 'react-icons/io5';
import puce from './images/puce.svg';
import styles from './ListEditor.module.scss';

const ListEditor: React.FC<any> = ({ name = '', control, isNumber = true }: { name?: string, control?: Control<any>, isNumber: boolean }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <div className={styles.container}>
      <ul>
        {fields.map((field: any, index: number) => (
          <li key={field.id}>
            <div className={styles.icon}>
              {isNumber
                ? <div className={styles.index}>
                {index + 1}
              </div>
                : <img src={puce} alt='puce' />
              }
            </div>
            <Controller
              name={`${name}.${index}.value`}
              control={control}
              defaultValue={field.value}
              render={(f) => (
                <input className={styles.input} {...f.field} type="text" />
              )}
            />
            <button type="button" onClick={() => remove(index)}>
              <IoTrash />
            </button>
          </li>
        ))}
      </ul>

      <button
        type="button"
        className={styles.add}
        onClick={() => append({ id: new Date().getTime().toString(), value: '' })}
      >
        Ajouter un item
      </button>
    </div>
  );
};

export default ListEditor;
