import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  BiLink,
  BiPlus,
} from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import deleteFile from '../../actions/file';
import {
  getSceneAction,
  getSceneLandmarksAction,
  postLandmarksAction,
  putLandmarkAction,
  putSceneAction,
  updateSceneAction,
} from '../../actions/scene';
import InputFile from '../../components/InputFile/InputFile';
import Loader from '../../components/Loader';
// import Viewer360 from '../../components/viewer360';
import { useAppDispatch } from '../../reducers';
import styles from './EditScene.module.scss';
import PanoramaViewer from '../../components/PanoramaViewer';
import {
  GET_LANDMARK, GET_SCENE, GET_SCENE_LANDMARKS, SET_TOAST,
} from '../../actions/types';
import { MarkerPos } from '../../types/scene';

export default function EditScene() {
  const { sceneId } = useParams();
  const dispatch = useAppDispatch();
  // const cameraRef = useRef<any>(null);
  const { scene, landmarks, isLoading } = useSelector((d: any) => d.scenesReducer);
  const isAddRef = useRef<any>(null);
  const [isAdd, setIsAdd] = useState(false);
  const sceneIsLoading = isLoading?.find(
    (l: string) => l === GET_SCENE_LANDMARKS,
  );

  const picture = !sceneIsLoading && landmarks && scene?.picture?.path && `${process.env.REACT_APP_API_URL}/public/${scene.picture.path}`;

  const getScene = useCallback(() => {
    if (sceneId) {
      isAddRef.current = false;
      setIsAdd(false);
      dispatch({
        type: GET_SCENE,
        payload: null,
      });
      dispatch({
        type: GET_LANDMARK,
        payload: null,
      });
      dispatch({
        type: GET_SCENE_LANDMARKS,
        payload: null,
      });
      getSceneAction(dispatch, sceneId);
      getSceneLandmarksAction(dispatch, sceneId);
    }
  }, [dispatch, sceneId]);

  async function handleChangePicture(id: string) {
    if (scene.picture?._id) {
      await deleteFile(dispatch, scene.picture?._id);
    }
    await putSceneAction(dispatch, { ...scene, picture: id || null });
  }

  useEffect(() => {
    getScene();
  }, [sceneId]);

  const handleAdd = async (position: MarkerPos) => {
    const defaultMarker : any = {
      position,
      scene: sceneId,
      name: 'Nouveau marker',
    };
    if (isAddRef.current) {
      const res = await postLandmarksAction(dispatch, defaultMarker);
      isAddRef.current = false;
      setIsAdd(false);
      return res;
    }
    return null;
  };

  const handlePutMarker = async (id: string, position: MarkerPos) => {
    if (id) {
      const res = await putLandmarkAction(dispatch, id, { position }, true);
      isAddRef.current = false;
      setIsAdd(false);
      return res;
    }
    return null;
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_API_URL}/redirect?referer=b@k@s@ble&showroom=${sceneId}`);
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'L\'url de la page a été copie',
      },
    });
  };

  return (
    <div className={styles.container}>
      {sceneId && scene && !sceneIsLoading
        ? <>
          <header>
            <h1>{scene.name}</h1>
            {picture
                && <div className={styles.actions}>
              <div className={styles.new}>
                <h4>Nouveauté :</h4>
                <button
                  type="button"
                  className={`${styles.switch} ${scene.isNovelties ? styles.active : ''}`}
                  onClick={() => updateSceneAction(dispatch, scene?._id, {
                    isNovelties: !scene.isNovelties,
                  })}
                >
                  <p>On</p>
                  <p>Off</p>
                  <div className={styles.round}/>
                </button>
              </div>
              <button
                className={`${styles.btn} ${styles.export}`}
                onClick={() => copyUrl()}
              >
                <BiLink />
              </button>
              <button type="button" className={`${styles.btn} ${styles['with-icon']}`}
                  onClick={() => {
                    setIsAdd(!isAddRef.current);
                    isAddRef.current = !isAddRef.current;
                    dispatch({
                      type: GET_LANDMARK,
                      payload: null,
                    });
                  }}>
                    {!isAdd && <BiPlus />}
                    {isAdd ? 'Annuler l\'ajout' : 'Ajouter un repère'}
                </button>
              </div>
            }
          </header>
          <div className={`${styles.img} ${isAdd ? styles.add : ''}`}>
            <div className={styles.file}>
              <InputFile
                id={sceneId}
                submit={(fileId: string) => handleChangePicture(fileId)}
                className="primary"
                btnText={scene?.picture ? 'Changer la photo' : 'Ajouter une photo'}
              />
              </div>
              {picture
                && <PanoramaViewer
                  picture={picture}
                  markers={landmarks}
                  addMarker={(pos: MarkerPos) => handleAdd(pos) || null}
                  putMarker={(id: string, pos: MarkerPos) => handlePutMarker(id, pos)}
                  putScene={null}
                  scenes={null}
                />
              }
            </div>
        </>
        : <div className={styles.loader}>
        <Loader />
      </div>
      }
    </div>
  );
}
