import React from 'react';
import { IField } from '../../types/scene';
import close from './images/close.svg';
import styles from './MediaModale.module.scss';

export default function MediaModale({
  media,
  closeModale,
}:{
  media: IField | null
  closeModale: () => void;
}) {
  const mediaPath = media?.media?.path && `${process.env.REACT_APP_API_URL}/public/${media.media.path}`;
  return (
    <div className={`${styles.container} ${mediaPath ? styles.active : ''}`}>
      {mediaPath
        && <div className={`${styles.media} ${media?.media?.mimetype === 'video/mp4' ? styles.video : ''}`}>
          <button
            type="button"
            className={styles.close}
            onClick={() => closeModale()}
          >
              <img
              src={close}
              alt='close'
            />
          </button>
          {(media?.media?.mimetype === 'image/png' || media?.media?.mimetype === 'image/jpeg')
            && <img src={mediaPath} alt="cover"/>
          }
          {(media?.media?.mimetype === 'video/mp4')
            && <video controls>
              <source src={mediaPath} type="video/mp4" />
              <p>
                Votre navigateur ne prend pas en charge les vidéos HTML5. Voici
                <a href={mediaPath}>un lien pour télécharger la vidéo</a>.
              </p>
            </video>
          }

        </div>
      }
    </div>
  );
}
