// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_PREVIOUS_HISTORY = 'SET_PREVIOUS_HISTORY';
export const SET_TOAST = 'SET_TOAST';

// SCENES ACTIONS
export const GET_SCENES = 'GET_SCENES';
export const GET_SCENE = 'GET_SCENE';
export const PUT_SCENE = 'PUT_SCENE';
export const GET_SCENES_LIST = 'GET_SCENES_LIST';
export const SCENE_ERROR = 'SCENE_ERROR';
export const SCENE_LOADING = 'SCENE_LOADING';
export const FILE_ERROR = 'FILE_ERROR';
export const GET_SCENE_LANDMARKS = 'GET_SCENE_LANDMARKS';
export const POST_LANDMARK = 'POST_LANDMARK';
export const DELETE_LANDMARK = 'DELETE_LANDMARK';
export const GET_LANDMARK = 'GET_LANDMARK';
export const PUT_LANDMARK = 'PUT_LANDMARK';
