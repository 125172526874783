import { Dispatch } from 'redux';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import fileDownload from 'js-file-download';
import {
  postData,
  getData,
  getFile,
} from './index';
import {
  AUTH_SIGNIN,
  AUTH_GET_PROFILE,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
  SET_PREVIOUS_HISTORY,
} from './types';

export interface RequestFunction {
  (
    dispatch: Dispatch,
    data: any,
  ) : any
}

export const signIn: RequestFunction = async (dispatch, data) => {
  const url = '/auth/login';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  const user = response?.data?.user;
  const token = response?.data?.token;

  if (user && token) {
    dispatch({
      type: AUTH_SIGNIN,
      payload: { user, token },
    });
  }
  return response;
};

export const forgotPassword: RequestFunction = async (dispatch, data) => {
  const url = '/auth/forgot-password';
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  return response;
};

export const resetPassword: RequestFunction = async (dispatch, data) => {
  const url = `/auth/reset-password/${data.token}`;
  const response = await postData(AUTH_ERROR, url, dispatch, { password: data.password }, false);
  return response;
};

export const logout = (dispatch: Dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  });
  return true;
};

export const getProfileAction = async (dispatch: Dispatch) => {
  const url = '/users/profile';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  if (response.data) {
    const responseIp = await axios.get('https://api.ipify.org/?format=json');
    const user = { ...response.data, ip: responseIp.data.ip };
    dispatch({
      type: AUTH_GET_PROFILE,
      payload: user,
    });
  }
};

export const resetError = (dispatch: Dispatch) => {
  dispatch({
    type: AUTH_RESET_ERROR,
  });
};

export const createHistoryAction: RequestFunction = async (dispatch, data) => {
  const url = '/history';
  const response = await postData(AUTH_ERROR, url, dispatch, data, true);
  if (response?.data?.history?._id) {
    dispatch({
      type: SET_PREVIOUS_HISTORY,
      payload: response?.data?.history?._id,
    });
  }
  return response;
};

export const exportHistoryAction = async (dispatch: Dispatch) => {
  const url = '/history/export';
  const xlsxExport : any = await getFile(AUTH_ERROR, url, dispatch, true);
  if (xlsxExport) {
    fileDownload(xlsxExport, 'historique-de-navigation.xlsx');
  }
};
