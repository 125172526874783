import {
  FILE_ERROR,
  GET_SCENE,
  GET_SCENES,
  GET_SCENES_LIST,
  GET_SCENE_LANDMARKS,
  POST_LANDMARK,
  GET_LANDMARK,
  PUT_SCENE,
  SCENE_ERROR,
  SCENE_LOADING,
  DELETE_LANDMARK,
} from '../actions/types';
import { ActionReducer } from '../types';
import { ILandmark, IScene } from '../types/scene';

interface SceneState {
  lists: any | null,
  isLoading: string[],
  error: string | null,
  scene: IScene | null,
  scenes: IScene[] | null,
  fileError: string | null,
  landmark: ILandmark | null,
  landmarks: ILandmark[],
}

const DEFAULT_STATE: SceneState = {
  lists: null,
  scenes: null,
  scene: null,
  landmark: null,
  landmarks: [],
  isLoading: [],
  error: null,
  fileError: null,
};

// eslint-disable-next-line default-param-last
const scenesReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  switch (action.type) {
    case GET_SCENES_LIST:
      updatedState = {
        ...state,
        lists: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case GET_SCENES:
      updatedState = {
        ...state,
        scenes: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case PUT_SCENE:
      updatedState = {
        ...state,
        scene: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case GET_SCENE_LANDMARKS:
      updatedState = {
        ...state,
        landmarks: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case POST_LANDMARK:
      updatedState = {
        ...state,
        landmarks: [...state.landmarks, action.payload],
        isLoading: state.isLoading.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case DELETE_LANDMARK:
      updatedState = {
        ...state,
        landmarks: state.landmarks.filter((d) => d._id !== action.payload),
        isLoading: state.isLoading.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case GET_LANDMARK:
      updatedState = {
        ...state,
        landmark: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case GET_SCENE:
      updatedState = {
        ...state,
        scene: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case FILE_ERROR:
      updatedState = {
        ...state,
        fileError: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case SCENE_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case SCENE_ERROR:
      updatedState = { ...state, error: null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default scenesReducer;
